<template>
  <div class="content">
    <PageHeader :title="$t('flows.header')" />
    <div class="page-content container-fluid">
      <div class="card">
        <div class="card-header">
          <form @submit.prevent="search(1)">
            <div class="row">
              <div class="col-lg-6 col-md-6 col-sm-12 to-left">
                <div class="input-group">
                  <div class="input-group-prepend">
                    <b-button v-b-modal.flow-create class="btn btn-success emit-j-event">
                      <i class="icon dripicons-plus"></i
                      ><span class="hide-m">Novo fluxo</span>
                    </b-button>
                    <button
                      type="button"
                      class="btn btn-danger btn-remove border-radius-right" style="border-top-right-radius: 5px;border-bottom-right-radius: 5px;"
                      @click="deleteAll"
                      :class="{ disabled: selectedFlows.length == 0 }"
                      :disabled="selectedFlows.length == 0"
                    >
                      <i class="icon dripicons-trash color-white"></i
                      ><span class="hide-m">{{$t('generic-str.delete')}}</span>
                    </button>
                  </div>
                </div>
              </div>

              <div class="col-lg-6 col-md-6 col-sm-12 input-group">
                <input
                  type="text"
                  class="form-control to-left"
                  :placeholder="$t('generic-str.search-by.name')"
                  v-model="nameFlow"
                />
                <button
                  type="submit"
                  class="btn btn-danger to-right btn-search"
                >
                  {{$t('generic-str.filter')}}
                </button>
              </div>
            </div>
          </form>
        </div>
        <div class="card-body block-el p-0">
          <div class="responsive-table">
            <div
              v-if="!flows.length && fetched"
              class="text-center table-placeholder"
            >
              <br>
              <br>
              <i class="fas fa-sitemap font-size-80"></i>
              <h5 class="card-title m-t-20">Nenhum flow cadastrado</h5>
              <p>Cadastre um flow para começar</p>
            </div>
            <table v-if="fetched" class="table">
              <thead v-if="flows.length" class="bg-light">
                <tr>
                  <th class="w-60" scope="col" style="width: 40px;">
                    <div class="custom-control custom-checkbox">
                      <input
                        v-model="selectAllFlows"
                        type="checkbox"
                        class="custom-control-input"
                        id="customCheck1"
                      />
                      <label
                        class="custom-control-label"
                        for="customCheck1"
                      ></label>
                    </div>
                  </th>
                  <th scope="col" style="text-align: left;">{{$tc('generic-str.name', 1)}}</th>
                  <th scope="col">Ultima edição</th>
                  <th scope="col">Tipo</th>
                  <th scope="col">Status</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(flow, i) in flows" :key="flow.id">
                  <td data-label>
                    <div class="custom-control custom-checkbox">
                      <input
                        v-model="selectedFlows"
                        type="checkbox"
                        class="custom-control-input"
                        :id="'flow' + i"
                        :value="flow"
                      />
                      <label
                        class="custom-control-label"
                        :for="'flow' + i"
                      ></label>
                    </div>
                  </td>
                  <td data-label="Nome" style="text-align: left;">
                    {{ flow.name }}
                    <p>{{ flow.description }}</p>
                  </td>
                  <td data-label="Data">
                    {{ flow.last_update | relativeDate }}
                  </td>
                  <td data-label="Tipo">
                    <span class="badge badge-info" v-if="flow.type_trigger == 'WEBHOOK'">
                      <i class="fas fa-wrench color-white"></i> Webhook
                      <strong v-if="flow.channel_id == whatsId">
                        (WhatsApp)
                      </strong>
                      <strong v-if="flow.channel_id == messengerId">
                        (Messenger)
                      </strong>
                      <strong v-if="flow.channel_id == instagramId">
                        (Instagram)
                      </strong>
                    </span>
                    <span class="badge badge-info" v-if="flow.type_trigger == 'CHANNEL'">
                      <i class="fas fa-comment color-white"></i> Canal
                      <strong v-if="flow.channel_id == whatsId">
                        (WhatsApp)
                      </strong>
                      <strong v-if="flow.channel_id == messengerId">
                        (Messenger)
                      </strong>
                      <strong v-if="flow.channel_id == instagramId">
                        (Instagram)
                      </strong>
                    </span>
                    <span class="badge badge-danger" v-if="flow.channel_id !== whatsId">
                      <i class="fas fa-comment color-white"></i> {{flow.identification_name}}
                    </span>
                  </td>
                  <td data-label="Status">
                    <span class="badge badge-success" v-if="flow.active">
                      Publicado
                    </span>
                    <span class="badge badge-danger" v-if="!flow.active">
                      Inativo
                    </span>
                    <span class="badge badge-gray m-left-5" v-if="flow.draft">
                      Rascunho
                    </span>
                  </td>
                  <td data-label style="text-align: right;">
                    <toggle-button v-model="flow.active" @change="changeToggle(flow)"/>
                    <div class="relative w100">
                      <div>
                        <div class="dropdown">
                          <a
                            role="button"
                            class="dropdown-action"
                            :to="'#menu-dropdown-' + i"
                            type="button"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            <i class="icon dripicons-gear text-success"></i>
                          </a>
                          <div
                            class="dropdown-menu dropdown-menu-right"
                            :id="'menu-dropdown-' + i"
                            x-placement="bottom-end"
                            style
                          >
                            <a class="dropdown-item emit-j-even hide" @click="history(flow.id)">
                              {{$t('generic-str.revision-history')}}
                            </a>
                            <a class="dropdown-item emit-j-even" @click="edit(flow.id)">
                              {{$t('generic-str.edit')}}
                            </a>
                            <a class="dropdown-item emit-j-even hide" @click="clone(flow.id)">
                              {{$t('generic-str.duplicate')}}
                            </a>
                            <a class="dropdown-item" @click="remove(flow.id)">
                              {{$t('generic-str.remove')}}
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <div
              v-else
              class="qt-block-ui relative"
              style="padding: 120px"
            />
            <br />
            <pagination :lastPage="pages" @change="fetch" />
          </div>
        </div>
      </div>
    </div>

    <!-- modal para criar novo item -->
    <b-modal id="flow-create" hide-footer class="modal-lg" size="lg">
      <template #modal-title>
        Novo flow
      </template>
      <div style="padding: 20px;">
        <div class="row text-center" v-if="flowLoading">
          <img src="assets/img/loading.gif" style="margin: 0 auto;"/>
        </div>
        <div class="row" v-if="!flowLoading">
          <div class="col-sm-12">
            <input placeholder="Nome" class="form-control" v-model="newFlow.name">
            <br>
          </div>
          <div class="col-sm-12">
            <textarea placeholder="Descrição" class="form-control" v-model="newFlow.description"></textarea>
            <hr>
          </div>
          <div class="col-sm-12">
            <h3 style="margin-bottom: 15px;">Tipo de gatilho</h3>
          </div>
          <div class="col-sm-6">
            <div class="type-flow-item text-center" @click="changeTrigger('WEBHOOK')" :class="{'type-flow-item-active': newFlow.type_trigger == 'WEBHOOK'}">
              <i class="fas fa-wrench"></i>
              Webhook
              <p>Integre e dispare o flow através dos nossos Webhook.</p>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="type-flow-item text-center" @click="changeTrigger('CHANNEL')" :class="{'type-flow-item-active': newFlow.type_trigger == 'CHANNEL'}">
              <i class="fas fa-comment"></i>
              Canal
              <p>
                Dispare o flow quando o seu cliente enviar uma mensagem através dos canais.
              </p>
            </div>
          </div>
        </div>
        <div v-if="!flowLoading" class="row" style="margin-top: 20px;">
          <div class="col-sm-12">
            <h3 style="margin-bottom: 15px;">Escolha um canal</h3>
            <div class="card p20">
              <div class="row" style="padding: 10px;">
                <div class="col-sm-4" v-if="newFlow.type_trigger != 'WEBHOOK'">
                  <div class="text-center type-flow-item" v-tooltip.top="'Instagram'" @click="changeChannel(instagramId, 'instagram')" :class="{'type-flow-item-active': newFlow.channel_id == instagramId}">
                    <i class="fab fa-instagram flow-trigger-icon"></i>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="text-center type-flow-item" v-tooltip.top="'WhatsApp'" @click="changeChannel(whatsId)" :class="{'type-flow-item-active': newFlow.channel_id == whatsId}">
                    <i class="fab fa-whatsapp flow-trigger-icon"></i>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="text-center type-flow-item" v-tooltip.top="'WhatsApp Meta'" @click="changeChannel(whatsIdLive)" :class="{'type-flow-item-active': newFlow.channel_id == whatsIdLive}">
                    <i class="fab fa-whatsapp flow-trigger-icon"></i>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="text-center type-flow-item" v-tooltip.top="'Telegram'" @click="changeChannel(telegramId)" :class="{'type-flow-item-active': newFlow.channel_id == telegramId}">
                    <i class="fab fa-telegram flow-trigger-icon"></i>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="text-center type-flow-item" v-if="newFlow.type_trigger != 'WEBHOOK'" v-tooltip.top="'Messenger'" @click="changeChannel(messengerId)" :class="{'type-flow-item-active': newFlow.channel_id == messengerId}">
                    <i class="fab fa-facebook-messenger flow-trigger-icon"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-12" v-if="newFlow.channel_id != whatsIdLive">
            <h3 style="margin-bottom: 15px;">Escolha uma página</h3>
            <div class="card p20">
              <div class="row" style="padding: 10px;">
                <div class="col-sm-6" v-for="(p) in this.pagesChannel" :key="p.index">
                  <div class="text-center type-flow-item" @click="changePage(p.external_id)" :class="{'type-flow-item-active': newFlow.account_channel_external_id == p.external_id}">
                    <span class="label label-info">{{p.identification_name ? p.identification_name : p.external_id}}</span>
                  </div>
                </div>
              </div>
              <div class="row" style="padding: 40px; padding-top: 10px; padding-bottom: 10px;" v-if="pagesChannel.length == 0">
                <div class="alert alert-info w-100">Nenhum item encontrado</div>
              </div>
            </div>
          </div>
          <div class="col-sm-12" v-if="newFlow.channel_id == whatsIdLive">
            <h3 style="margin-bottom: 15px;">Escolha um número</h3>
            <div class="card p20">
              <div class="row" style="padding: 10px;">
                <div class="col-sm-6" v-for="(p) in this.pagesChannel" :key="p.index">
                  <div class="text-center type-flow-item" @click="changePage(p.external_id)" :class="{'type-flow-item-active': newFlow.account_channel_external_id == p.external_id}">
                    <span class="label label-info">{{p.identification_name ? p.identification_name : p.external_id}}</span>
                  </div>
                </div>
              </div>
              <div class="row" style="padding: 40px; padding-top: 10px; padding-bottom: 10px;" v-if="whatsNumbers.length == 0">
                <div class="alert alert-info w-100">Nenhum item encontrado</div>
              </div>
            </div>
          </div>
          <div class="col-sm-12" v-if="newFlow.channel_id == whatsId && newFlow.type_trigger == 'WEBHOOK'">
            <h3 style="margin-bottom: 15px;">Nome dos campos</h3>
            <div class="card p20">
              <div class="row" style="padding: 10px;">
                <div class="col-sm-4">
                  <input placeholder="Campo de nome" class="form-control" v-model="newFlow.client_name_field" />
                </div>
                <div class="col-sm-4">
                  <input placeholder="Campo de email" class="form-control" v-model="newFlow.client_email_field" />
                </div>
                <div class="col-sm-4">
                  <input placeholder="Campo de telefone" class="form-control" v-model="newFlow.client_phone_field" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row" v-if="newFlow.channel_id && !flowLoading">
          <div class="col-sm-12 text-right">
            <hr>
            <button class="btn btn-success" @click="createFlow">Salvar</button>
          </div>
        </div>
        <button @click="create('blank')" class="hide">{{$t('flows.blank')}}</button>
      </div>
    </b-modal>
  </div>
</template>

<script>
// @ is an alias to /src
import PageHeader from '@/components/PageHeader.vue';
import Pagination from '@/components/Pagination.vue';
import Swal from 'sweetalert2';
import axios from '@/plugins/axios';
import { ToggleButton } from 'vue-js-toggle-button';
import ChannelService from '@/services/channel.service';

const webApi = axios();

export default {
  name: 'ListFlows',
  components: {
    PageHeader,
    Pagination,
    ToggleButton,
  },
  data() {
    return {
      fetched: false,
      nameFlow: null,
      flowLoading: false,
      instagramId: process.env.VUE_APP_INSTAGRAM_ID,
      whatsId: process.env.VUE_APP_WHATS_ID,
      whatsIdLive: process.env.VUE_APP_WHATS_MIDDLE_ID,
      messengerId: process.env.VUE_APP_MESSENGER_ID,
      telegramId: process.env.VUE_APP_TELEGRAM_ID,
      flows: [],
      form: {
        q: '',
        page: 1,
      },
      pages: 1,
      newFlow: {
        name: '',
        description: '',
        channel_id: '',
        type_trigger: '',
        version: 2,
        account_channel_external_id: '',
      },
      selectedFlows: [],
      Toast: {},
      pagesChannel: [],
      whatsNumbers: [],
    };
  },
  computed: {
    selectAllFlows: {
      get() {
        if (this.flows) {
          return this.selectedFlows.length === this.flows.length;
        }
        return false;
      },
      set(value) {
        const selected = [];

        if (value) {
          this.flows.forEach((flow) => {
            selected.push(flow);
          });
        }

        this.selectedFlows = selected;
      },
    },
  },
  created() {
    this.fetch(1);
    this.Toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      onOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer);
        toast.addEventListener('mouseleave', Swal.resumeTimer);
      },
    });
    this.getWhatsAccount();
  },
  methods: {
    getWhatsAccount(channel) {
      this.flowLoading = true;
      this.newFlow.channel_id = channel;
      this.whatsNumbers = [];
      webApi.get('whatsapp/new/numbers').then((response) => {
        console.log('WhatsNumbers');
        console.log(response);
        this.flowLoading = false;
        response.data.forEach((data) => {
          if (data.deleted === false) {
            this.whatsNumbers.push(data);
          }
        });
      }, (error) => {
        this.content = error;
        this.flowLoading = false;
      });
    },
    changeToggle(flow) {
      webApi.put(`flow/${flow.id}`, {
        active: flow.active,
      }).then(() => {
        this.$toast.show({
          title: 'Successo',
          content: 'Flow atualizado',
          type: 'success',
        });
      }, (error) => {
        console.log(error);
        this.$toast.show({
          title: 'Erro',
          content: 'Erro interno, por favor tente novamente mais tarde ou contact o suporte para ajuda.',
          type: 'error',
        });
      });
    },
    changeStatus(status) {
      console.log(status);
    },
    changePage(page) {
      this.newFlow.account_channel_external_id = page;
    },
    changeTrigger(trigger) {
      this.newFlow.type_trigger = trigger;
      this.$forceUpdate();
    },
    changeChannel(channel, name) {
      this.newFlow.channel_id = channel;
      if (name === 'instagram') {
        this.getInstagram();
      } else {
        this.getAccountChannel(channel);
      }
      this.$forceUpdate();
    },
    search(page) {
      this.form.page = page;
      this.fetched = false;

      // simulado
      this.fetched = true;
      this.flows = [];
      this.pages = 1;
      // simulado

      // Requisição back-end (o this.form contem a "filtagem/busca")
      webApi.get(`flow/search/${this.nameFlow}`, this.form).then((response) => {
        console.log('Flow');
        console.log(response);
        this.fetched = true;
        this.flows = response.data;
        this.pages = response.last_page;
      }, (error) => {
        this.content = error;
      });
    },
    fetch(page) {
      this.form.page = page;
      this.fetched = false;

      // simulado
      this.fetched = true;
      this.flows = [];
      this.pages = 1;
      // simulado

      // Requisição back-end (o this.form contem a "filtagem/busca")
      webApi.get('flows', this.form).then((response) => {
        console.log('Flow');
        console.log(response);
        this.fetched = true;
        this.flows = response.data;
        this.pages = response.last_page;
      }, (error) => {
        this.content = error;
      });
    },
    saveFlow() {
      this.flowLoading = true;
      console.log(this.newFlow);
    },
    getInstagram() {
      this.flowLoading = true;
      ChannelService.instagramPage(process.env.VUE_APP_INSTAGRAM_LIVE_ID)
        .then(
          (response) => {
            console.log('Get Instagrams');
            console.log(response);
            this.pagesChannel = [];
            response.data.forEach((item) => {
              this.pagesChannel.push({
                identification_name: item.name,
                external_id: item.identification,
              });
            });
            this.flowLoading = false;
          },
          (error) => {
            this.$toast.show({
              title: 'Erro',
              content: error.response.data.message,
              type: 'danger',
            });
            this.flowLoading = false;
          },
        )
        .catch((error) => {
          console.log(error.response);
          this.flowLoading = false;
        });
    },
    getAccountChannel(channel) {
      this.flowLoading = true;
      webApi.get(`flows/account_channel/${channel}`).then((response) => {
        console.log('Channels');
        console.log(response);
        this.pagesChannel = [];
        response.data.forEach((item) => {
          if (item.deleted === 'false') {
            this.pagesChannel.push(item);
          }
        });
        this.flowLoading = false;
      }, (error) => {
        this.content = error;
        this.flowLoading = false;
      });
    },
    createFlow() {
      console.log(this.newFlow);
      if (this.newFlow.name === null || this.newFlow.name.length === 0) {
        this.$toast.show({
          type: 'danger',
          title: 'Flow',
          content: 'O nome precisa ser preenchido',
        });
        return;
      }
      if (this.newFlow.channel_id === '') {
        this.$toast.show({
          type: 'danger',
          title: 'Flow',
          content: 'Você vai precisar escolher um canal',
        });
        return;
      }
      if (this.newFlow.type_trigger === '') {
        this.$toast.show({
          type: 'danger',
          title: 'Flow',
          content: 'Você vai precisar escolher a ação',
        });
        return;
      }
      if (this.newFlow.account_channel_external_id === '') {
        this.$toast.show({
          type: 'danger',
          title: 'Flow',
          content: 'Você vai precisar escolher uma página',
        });
        return;
      }
      this.flowLoading = true;
      webApi.post('flow', this.newFlow).then((response) => {
        console.log('CreateFlow');
        console.log(response);
        const id = response.data.id;
        this.$router.push({ name: 'FlowEdit', params: { id } });
        this.flowLoading = false;
      }, (error) => {
        this.content = error;
        this.flowLoading = false;
        if (error.response.data.message.includes('409 Conflict')) {
          this.$toast.show({
            title: 'Erro de validação',
            content: 'Ops, talvez já exista um flow para esse canal',
            type: 'danger',
          });
        } else {
          this.$toast.show({
            title: `${this.$t('sms.infos.filters.status.lbl-error')} ${error.code}`,
            content: error.message,
            type: 'danger',
          });
        }
      });
    },
    remove(id) {
      Swal.fire({
        title: this.$t('generic-str.r-sure'),
        text: 'O fluxo será excluído permanentemente. Você não poderá reverter isso!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('warnings.btn-y-delete'),
      }).then((result) => {
        if (result.isConfirmed) {
          webApi.delete(`flow/${id}`).then((response) => {
            this.fetch(this.form.page);
            Swal.fire(`${this.$t('generic-str.deleted')}!`, 'Seu fluxo foi excluído.', 'success');
          }, (error) => {
            this.content = error;
          });
        }
      });
    },
    create(type) {
      switch (type) {
        case 'import':
          console.warn('Importar');
          break;
        case 'base':
          console.warn('Criar a partir de base');
          break;
        case 'blank':
          this.$router.push({ name: 'FlowCreate' });
          break;
        default:
          console.error('Nada foi selecionado');
      }
    },
    history(id) {
      console.warn(`Historico de ${id}`);
    },
    edit(id) {
      this.$router.push({ name: 'FlowEdit', params: { id } });
    },
    clone(id) {
      console.warn(`Duplicar ${id}`);
    },
    deleteAll() {
      Swal.fire({
        title: 'Remover fluxos selecionados',
        text: 'Tem certeza que gostaria de remover os fluxos?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('generic-str.yes'),
      }).then((result) => {
        if (result.value) {
          const toDelete = [];
          this.selectedFlows.forEach((item) => {
            toDelete.push(item.id);
          });

          webApi.post('flows/delete-all', { flows: this.selectedFlows }).then((response) => {
            this.fetch(this.form.page);
            Swal.fire(
              `${this.$t('generic-str.deleted')}!`,
              'Seus fluxos foram excluídos.',
              'success',
            );
            this.selectedFlows = [];
          }, (error) => {
            this.$toast.show({
              title: `${this.$t('sms.infos.filters.status.lbl-error')} ${error.code}`,
              content: error.response.data.message,
              type: 'danger',
            });
          });
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.custom-control-label {
  &::after,
  &::before {
    top: -0.5rem;
  }
}
.btn i {
  line-height: 1;
  color: inherit;
}
.btn {
  margin-bottom: 5px;
}
.btn-search {
  margin-left: 8px;
}
.type-flow-item {
  border-radius: 6px;
  border: 1px solid #ced4da !important;
  padding: 10px;
  cursor: pointer;
  margin-bottom: 5px;
}
.type-flow-item:hover {
  background: #752de6;
  border: 1px solid #752de6 !important;
  color: #fff;
}
.type-flow-item:hover i{
  color: #fff;
}
.type-flow-item-active {
  background: #752de6;
  color: #fff;
}
.type-flow-item-active i {
  color: #fff;
}
.flow-trigger-icon {
  font-size: 25px;
  cursor: pointer;
}
.color-white{
  color: #fff !important;
}
.m-left-5{
  margin-left: 5px !important;
}
</style>
